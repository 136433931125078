<template>
  <div class="bp-modal-discount noselect flex--col-center-center">
    <button class="bp-modal-discount__close-cross" @click="closeModal">
      <Cross class="bp-modal-discount__close-cross-svg" />
    </button>
    <div class="bp-modal-discount__background">
      <MediaItem :elementId="'discount_modal_background'">
        <template v-slot:default="slotProps">
          <bleach-image
            v-if="slotProps && slotProps.mediaItem && slotProps.mediaItem.image"
            class="bp-modal-discount__background-image"
            :src="slotProps.mediaItem.image[0].original_secure_url"
            :sizes="{
              sm: [500, 'ar_0.75']
            }"
            :default-size="[1200, 'ar_3']"
            noRetina
          />
        </template>
      </MediaItem>
    </div>
    <div class="bp-modal-discount__text-wrapper">
      <p class="bp-modal-discount__title text-editorial-super text--uppercase">
        {{ UI_ELEMENT("discount_modal_title") }}
      </p>
      <RichText
        class="bp-modal-discount__copy"
        zdClasses
        :content="getModalMessage"
      />
      <BasicButton
        class="bp-modal-discount__cta"
        large
        :buttonColor="getCtaColors.background"
        :textColor="getCtaColors.text"
        @click="closeModal"
        >{{ UI_ELEMENT("discount_modal_cta") }}</BasicButton
      >
      <p
        class="bp-modal-discount__smallprint text-body-small text--w-normal text--clr-white"
      >
        {{ UI_ELEMENT("discount_modal_smallprint") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import styles from "@/styles/settings.scss"
import ModalComponentMixin from "@/plugins/ModalContainer/ModalComponentMixin"

import RichText from "@/components/RichText"
import Cross from "@/assets/cross.svg"

export default {
  name: "DiscountModal",
  // specific config for the enter/leave animations for this modal
  transitions: {
    xs: ["center-slide-up", 300]
  },

  mixins: [ModalComponentMixin],

  components: {
    RichText,
    Cross
  },

  // mounted() {
  //   window.setTimeout(() => {
  //     this.emitClose()
  //   }, 3000)
  // },

  methods: {
    closeModal() {
      // All modals need to call this.emitClose() from their own close method
      this.emitClose()
    }
  },

  computed: {
    ...mapState("queryParams", ["queryParamsMap"]),

    getModalMessage() {
      return this.UI_TEXT_CONTENT("discount_modal_message", [
        {
          tag: "DISCOUNT_CODE",
          value: this.queryParamsMap.discount
        }
      ])
    },

    getCtaColors() {
      return {
        background: styles["COLOUR_bleach_white"],
        text: styles["COLOUR_bleach_black"]
      }
    }
  }
}
</script>
<style lang="scss">
$padding-top-default: 35px;
$padding-top-lg: 50px;

.bp-modal-discount {
  background-color: getcolour(bleach_purple);
  text-align: center;
  padding: $padding-top-default 30px 30px;
  pointer-events: all;

  @include media-breakpoint-up(lg) {
    padding: $padding-top-lg 30px 40px;
  }

  &__close-cross {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    top: 15px;
    right: 20px;
    width: 26px;
    height: 26px;
    padding: 3px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 0;

    // body:not(.mouse-nav) &:focus {
    //   outline: 2px solid getcolour(bleach_white);
    // }
  }

  &__close-cross-svg {
    path {
      stroke: getcolour(bleach_white);
      vector-effect: non-scaling-stroke;
      stroke-width: 1.5px;
    }
  }

  &__background,
  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__text-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 800px;
  }

  &__title {
    margin: 0 auto;
    max-width: 200px;

    @include media-breakpoint-up(md) {
      max-width: 500px;
    }
  }

  &__copy {
    margin: 10px auto 20px;

    @include media-breakpoint-up(md) {
      margin: 15px auto 30px;
    }
  }

  // &__cta {
  //   margin: 30px auto;
  // }

  &__smallprint {
    margin: 20px auto 0;

    @include media-breakpoint-up(md) {
      margin: 25px auto 0;
    }
  }

  &__reject {
    cursor: pointer;
    margin: 10px auto;

    @include media-breakpoint-up(md) {
      margin: 20px auto;
    }
  }
}
</style>
